.App {
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

.App-logo {
  pointer-events: none;
  max-width: 30vh;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}
